/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import { lazy } from "react";
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SubjectIcon from '@material-ui/icons/Subject';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ClassIcon from '@material-ui/icons/Class';
import ChaptersIcon from '@material-ui/icons/FormatColorText';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import GroupAddIcon from "@material-ui/icons/GroupAdd";
// Lazy Routes
const SignInContainer = lazy(() => import("views/Sign-in/Sign-in-container"));
const Curriculum = lazy(() => import("views/Curriculum/Curriculum"));
const Level = lazy(() => import("views/Level/Level"));
const Classes = lazy(() => import("views/Classes/Classes"));
const Subject = lazy(() => import("views/Subject/Subject"));
const Chapters = lazy(() => import("views/Chapters/Chapters"));
const Users = lazy(() => import("views/Users/Users"));
const Lesson = lazy(() => import("views/Lesson/Lesson"));
const Library = lazy(() => import("views/Library/Library"));
const Questions = lazy(() => import("views/Questions/Questions"));
const Feedback = lazy(() => import("views/Feedback/Feedback"));
const Test = lazy(() => import("views/Test/Test"));
const PreviousYearExam = lazy(() => import("views/PreviousYearExam/PreviousYearExam"));
const RefferalCodes = lazy(() => import("views/RefferalCodes/RefferalCodes"));
const Reports = lazy(() => import("views/Reports/Reports"))

const dashboardRoutes = [
  {
    path: "/curriculum",
    name: "Curriculum",
    rtlName: "إخطارات",
    icon: MenuBookIcon,
    component: Curriculum,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/level/:curriculumId",
    name: "Level",
    rtlName: "إخطارات",
    icon: AssessmentIcon,
    component: Level,
    layout: `${process.env.PUBLIC_URL}/admin`,
    hiddenFromSidebar: true
  },
  {
    path: "/classes",
    name: "Classes",
    rtlName: "إخطارات",
    icon: ClassIcon,
    component: Classes,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/subject",
    name: "Subject",
    rtlName: "إخطارات",
    icon: SubjectIcon,
    component: Subject,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/previous-year-exam",
    name: "Previous Year Exam",
    rtlName: "امتحان العام السابق",
    icon: CalendarTodayIcon,
    component: PreviousYearExam,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/chapters",
    name: "Chapters",
    rtlName: "إخطارات",
    icon: ChaptersIcon,
    component: Chapters,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PeopleIcon,
    component: Users,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/lesson",
    name: "Lessons",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CastForEducationIcon,
    component: Lesson,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/library",
    name: "Library",
    rtlName: "ملف تعريفي للمستخدم",
    icon: LibraryBooksIcon,
    component: Library,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/questions/:lessonId",
    name: "Questions",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CastForEducationIcon,
    component: Questions,
    layout: `${process.env.PUBLIC_URL}/admin`,
    hiddenFromSidebar: true
  },
  {
    path: "/feedback",
    name: "Feedback",
    rtlName: "ملف تعريفي للمستخدم",
    icon: FeedbackIcon,
    component: Feedback,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/test",
    name: "Test",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ListAltIcon,
    component: Test,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/referral-codes",
    name: "Referral Codes",
    rtlName: "رموز الإحالة",
    icon: GroupAddIcon,
    component: RefferalCodes,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/reports",
    name: "Reports",
    rtlName: "التقارير",
    icon: GroupAddIcon,
    component: Reports,
    layout: `${process.env.PUBLIC_URL}/admin`
  },
  {
    path: "/sign-in",
    name: "Sign In",
    rtlName: "إخطارات",
    icon: ExitToAppIcon,
    component: SignInContainer,
    layout: `${process.env.PUBLIC_URL}/admin`
  }
];

export default dashboardRoutes;
