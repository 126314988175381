import React, { Suspense, useEffect } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import RouteSpinner from "components/Spinners/RouteSpinner";
import { adminDetails } from "views/Sign-in/services";
import { setAuthState } from "views/Sign-in/actions";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { getRole, getToken } from "../utils/localStorage";
import request from "../utils/request";
// import { setAuthAuthenticated, setAuthState, setAuthLoading } from "./actions";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

// import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/favicon.png";

let ps;

const switchRoutes = () => {
  return <Suspense fallback={<RouteSpinner />}>
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === `${process.env.PUBLIC_URL}/admin`) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from={`${process.env.PUBLIC_URL}/admin`} to={`${process.env.PUBLIC_URL}/admin/${getToken() ? 'curriculum' : 'sign-in'}`} />
    </Switch>
  </Suspense>
};

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const token = getToken()
  const role = getRole()

  request.setAuthorizationToken(token);

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (auth.isLoading === null) {
      if (role === "ADMIN") {
        dispatch(adminDetails());
      } else if (role === "REFERRAL_MANAGER" || role === "REPORT_MANAGER") {
        dispatch(setAuthState({
          isAuthenticated: true,
          isLoading: false,
          user: {},
          role: role
        }));
      }
    }
    if ((!auth.isAuthenticated && auth.isLoading !== null && !auth.isLoading && !auth.role) || !getToken()) {
      dispatch(setAuthState({
        isAuthenticated: false,
        isLoading: false,
        user: {},
      }));
      return history.push(`${process.env.PUBLIC_URL}/admin/sign-in`);
    }
    //eslint-disable-next-line
  }, [dispatch, auth.isAuthenticated, auth.isLoading, history.location.pathname, auth.role]);

  const isFullyAuthenticated = auth.isAuthenticated && token;

  const signedInRoutes = isFullyAuthenticated
    ? routes.filter((route) => {
      switch (auth.role) {
        case "REFERRAL_MANAGER": {
          return route.path === "/referral-codes"
        }
        case "REPORT_MANAGER": {
          return route.path === "/reports"
        }
        case "ADMIN": {
          return route.path !== "/sign-in" && !route.hiddenFromSidebar
        }
        default: {
          return null
        }
      }

    })
    : routes.filter((route) => route.path === "/sign-in")

  const classes = useStyles();
  const mainPanel = React.createRef();
  // const [image, setImage] = React.useState(bgImage);
  //eslint-disable-next-line
  const [color, setColor] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={signedInRoutes}
        logoText={"E-Learning"}
        logo={logo}
        // image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes()}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes()}</div>
        )}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
