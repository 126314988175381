import { SET_AUTH_LOADING, SET_USER, SET_IS_AUTHENTICATED, SET_AUTH_STATE, SET_ROLE } from "./constants";

const initialState = {
    isAuthenticated: false,
    user: {},
    isLoading: null,
    role: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_LOADING: {
            return { ...state, isLoading: action.payload };
        }
        case SET_USER: {
            return {
                ...state,
                user: {
                    username: action.payload.username
                },
            };
        }
        case SET_IS_AUTHENTICATED: {
            return { ...state, isAuthenticated: action.payload };
        }
        case SET_ROLE: {
            return { ...state, role: action.payload }
        }
        case SET_AUTH_STATE: {
            return action.payload;
        }
        default:
            return state;
    }
};
