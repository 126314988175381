export const PAGE_ITEMS_COUNT = 5;

export const DEFAULT_ITEMS_COUNT = 1000;

export const URL_ADMIN_DETAILS = "/admin/details";
export const URL_ADMIN_LOGIN = "/admin/login";

export const URL_CURRICULUM_ADD = "/admin/curriculum/add";
export const URL_CURRICULUM_DELETE = "/admin/curriculum/delete";
export const URL_CURRICULUM_EDIT = "/admin/curriculum/edit";
export const URL_CURRICULUM_LIST = "/admin/curriculum/list";
export const URL_CURRICULUM_ICON_SAVE = "/admin/curriculum/icon/save";

export const URL_LEVEL_ADD = "/admin/level/add";
export const URL_LEVEL_DELETE = "/admin/level/delete";
export const URL_LEVEL_EDIT = "/admin/level/edit";
export const URL_LEVEL_LIST = "/admin/level/list";
export const URL_LEVEL_ICON_SAVE = "/admin/level/icon/save";

export const URL_CLASSES_ADD = "/admin/classes/add";
export const URL_CLASSES_DELETE = "/admin/classes/delete";
export const URL_CLASSES_EDIT = "/admin/classes/edit";
export const URL_CLASSES_LIST = "/admin/classes/list";

export const URL_SUBJECT_ADD = "/admin/subject/add";
export const URL_SUBJECT_DELETE = "/admin/subject/delete";
export const URL_SUBJECT_EDIT = "/admin/subject/edit";
export const URL_SUBJECT_LIST = "/admin/subject/list";
export const URL_SUBJECT_ICON_SAVE = "/admin/subject/icon/save";
export const URL_SUBJECT_BG_IMAGE_SAVE = "/admin/subject/bg-image/save";

export const URL_CHAPTER_ADD = "/admin/chapter/add";
export const URL_CHAPTER_DELETE = "/admin/chapter/delete";
export const URL_CHAPTER_EDIT = "/admin/chapter/edit";
export const URL_CHAPTER_LIST = "/admin/chapter/list";
export const URL_CHAPTER_ICON_SAVE = "/admin/chapter/icon/save";
export const URL_CHAPTER_UPLOAD_FILE = "/admin/chapter/file/upload";
export const URL_CHAPTER_DOWNLOAD_FILE = "/admin/chapter/file/get";

export const URL_LESSON_ADD = "/admin/lesson/add";
export const URL_LESSON_DELETE = "/admin/lesson/delete";
export const URL_LESSON_EDIT = "/admin/lesson/edit";
export const URL_LESSON_LIST = "/admin/lesson/list";
export const URL_LESSON_ACTIVATE = "/admin/lesson/activate";
export const URL_LESSON_VIDEO_UPLOAD = "/admin/lesson/video/stream/save";
export const URL_LESSON_VIDEO_DOWNLOAD = "/admin/lesson/video/stream/url/get";
export const URL_LESSON_FILE_UPLOAD = "/admin/lesson/file/upload";
export const URL_LESSON_FILE_DOWNLOAD = "/admin/lesson/file/get";
export const URL_LESSON_ICON_SAVE = "/admin/lesson/icon/save";

export const URL_BOOK_ADD = "/admin/book/add";
export const URL_BOOK_DELETE = "/admin/book/delete";
export const URL_BOOK_UPDATE = "/admin/book/update";
export const URL_BOOKS_LIST = "/admin/books/list";
export const URL_BOOK_FILE_UPDATE = "/admin/book/file/update";
export const URL_BOOK_FILE_DOWNLOAD = "/admin/book/get";

export const URL_QUESTION_ADD = "/admin/question/add";
export const URL_QUESTION_DELETE = "/admin/question/delete";
export const URL_QUESTION_EDIT = "/admin/question/edit";
export const URL_QUESTION_LIST = "/admin/question/list";

export const URL_FEEDBACK_REJECT = "/admin/feedback/reject";
export const URL_FEEDBACK_APPROVE = "/admin/feedback/approve";
export const URL_FEEDBACK_LIST = "/admin/feedback/list";

export const URL_ICON_DOWNLOAD = "/admin/resource/icon/get";
export const URL_ICON_DELETE = "/admin/resource/icon/delete";

export const URL_BG_IMAGE_DOWNLOAD = "/admin/resource/bg-image/get";
export const URL_BG_IMAGE_DELETE = "/admin/resource/bg-image/delete";

export const URL_USER_LIST = "/admin/user/list";

export const URL_TEST_LIST = "/admin/test/list";

export const URL_GET_OTP = "/admin/otp"
export const URL_UNBLOCK_USER = "/admin/user/unblock"

export const URL_PREVIOUS_YEAR_EXAM_ADD = "/admin/previous/year/exam/add"
export const URL_PREVIOUS_YEAR_EXAM_DELETE = "/admin/previous/year/exam/delete"
export const URL_PREVIOUS_YEAR_EXAM_EDIT = "/admin/previous/year/exam/edit"
export const URL_PREVIOUS_YEAR_EXAM_UPLOAD_FILE = "/admin/previous/year/exam/file/upload"
export const URL_PREVIOUS_YEAR_EXAM_DOWNLOAD_FILE = "/admin/previous/year/exam/file/get"
export const URL_PREVIOUS_YEAR_EXAM_LIST = "/admin/previous/year/exam/list"
export const URL_PREVIOUS_YEAR_EXAM_UPLOAD_VIDEO = "/admin/previous/year/exam/video/stream/save"
export const URL_PREVIOUS_YEAR_EXAM_DOWNLOAD_VIDEO = "/admin/previous/year/exam/video/stream/url/get"

export const URL_REFERRAL_CODE_ADD = "/admin/referral/code/add"
export const URL_REFERRAL_CODE_DELETE = "/admin/referral/code/delete"
export const URL_REFERRAL_CODE_LIST = "/admin/referral/code/list"

export const URL_INSTALATION_CHART = "/admin/report/installation/chart"
export const URL_PROFILE_CHART = "/admin/report/profile/chart"
export const URL_REFERRAL_CODE_USAGE = "/admin/referral/code/usage"

export const TABLE_HEADER = [
	{ value: "Name", width: "70%" },
	{ value: "Action", width: "30%" }
];

export const ORDERED_TABLE_HEADER = [
	{ value: "Name", width: "50%" },
	{ value: "Sort Order", width: "20%" },
	{ value: "Action", width: "30%" }
];

export const USER_TABLE_HEADER = [
	{ className: "user-name", value: "Name", width: "13%" },
	{ className: "user-status", value: "Birthday", width: "13%" },
	{ className: "user-status", value: "Msisdn", width: "13%" },
	{ className: "user-status", value: "Email", width: "13%" },
	{ className: "user-status", value: "Level", width: "13%" },
	{ className: "user-status", value: "Class", width: "13%" },
	{ className: "user-status", value: "Parents Code", width: "13%" },
	{ className: "user-status", value: "Actions", width: "10%" },
]

export const LESSON_TABLE_HEADER = [
	{ className: "user-name", value: "Name", width: "10%" },
	{ className: "user-status", value: "Subtitle", width: "10%" },
	{ className: "user-status", value: "Class", width: "10%" },
	{ className: "user-status", value: "Level", width: "10%" },
	{ className: "user-status", value: "Sort Order", width: "10%" },
	{ className: "user-status", value: "Status", width: "8%" },
	{ className: "user-status", value: "Subject", width: "10%" },
	{ className: "user-status", value: "Chapter", width: "10%" },
	{ className: "user-status lesson-user-actions", value: "Actions", width: "23%" },
]

export const PREVIOUS_YEAR_EXAM_TABLE_HEADER = [
	{ className: "user-status", value: "Year", width: "25%" },
	{ className: "prev-next-year-cell", value: "Subject", width: "25%" },
	{ className: "prev-next-year-cell", value: "Class", width: "25%" },
	{ className: "prev-next-year-cell", value: "Level", width: "25%" },
	{ className: "prev-next-year-cell-actions", value: "Actions", width: "25%" },
]

export const LIBRARY_TABLE_HEADER = [
	{ value: "Name", width: "25%" },
	{ value: "Pages", width: "25%" },
	{ value: "Size", width: "25%" },
	{ value: "Action", width: "25%" }
];

export const QUESTION_TABLE_HEADER = [
	{ className: "text-overflow-cell", value: "Question", width: "35%" },
	{ className: "text-overflow-cell", value: "Hint", width: "35%" },
	{ className: "text-overflow-cell", value: "Sort Order", width: "15%" },
	{ className: "text-overflow-cell", value: "Action", width: "15%" }
];

export const FEEDBACK_TABLE_HEADER = [
	{ className: "text-overflow-cell", value: "Lesson", width: "15%" },
	{ className: "text-overflow-cell", value: "Created At", width: "15%" },
	{ className: "text-overflow-cell", value: "Msisdn", width: "15%" },
	{ className: "text-overflow-cell", value: "Rating", width: "10%" },
	{ className: "", value: "Text", width: "20%" },
	{ className: "text-overflow-cell", value: "Status", width: "15%" },
	{ className: "text-overflow-cell", value: "Action", width: "10%" }
];

export const TEST_TABLE_HEADER = [
	{ value: "Lesson", width: "15%" },
	{ value: "Classes", width: "15%" },
	{ value: "Level", width: "15%" },
	{ value: "Msisdn", width: "15%" },
	{ value: "Subject", width: "20%" },
	{ value: "Status", width: "20%" }
];

export const REFERRAL_CODES_TABLE_HEADER = [
	{ value: "First Name", width: "20%" },
	{ value: "Last Name", width: "20%" },
	{ value: "Referral Code", width: "15%" },
	{ value: "Created At", width: "15%" },
	{ value: "Used Count", width: "15%" },
	{ value: "Actions", width: "15%" },
];