export const isEmpty = (item: any): boolean => {
    if (typeof item === "string") {
        if (item.length === 0) return true;
        if (item.length !== 0) return false;
    }
    if (!item || typeof item !== "object") return true;
    if (Array.isArray(item)) {
        return item.length > 0 ? false : true;
    }
    return !Object.keys(item).length;
};

export const isNumber = (value: any) => /^\d*$/.test(value);

export const isInteger = (value: any, acceptZero = false) => {
    if (!isNumber(value)) return false;
    if (!Number.isInteger(Number(value)) || [".", ",", " "].includes(value)) return false;
    // eslint-disable-next-line
    if (Number(value[0]) == 0 && !acceptZero) return false;
    return true;
};

export const isDecimal = (value: number) => (value % 1 !== 0 ? true : false);

export const getResData = (res, inCatch = false) => {
    const responseData = {
        success: false,
        result: null,
        data: null,
        error: null,
        total: null,
    };

    if (inCatch) {
        if (res && typeof res === "object" && isEmpty(res)) {
            responseData.error = res.toString();
        } else {
            responseData.error = res;
        }
        return responseData;
    }

    if (isEmpty(res)) return responseData;
    if (res.result === "error") {
        responseData.error = res.errorDesc;
        return responseData;
    }
    if (res.data && res.result) {
        responseData.success = true;
        responseData.result = res.result;
        responseData.data = res.data;
        responseData.total = res.total;
        return responseData;
    }
    return responseData;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1000; //1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};