import request from "../../utils/request";
import { URL_ADMIN_LOGIN, URL_ADMIN_DETAILS } from "../../constants";
import { getResData } from "../../utils/helpers";
import { saveToken } from "../../utils/localStorage";
import { setAuthState, setAuthLoading } from "./actions";

export const signIn = (submitData) => async (dispatch) => {
  try {
    const res = await request.post(URL_ADMIN_LOGIN, submitData);
    const resData = getResData(res.data);
    if (resData.success && resData.result) {

      if (typeof resData.data == "string") {
        request.setAuthorizationToken(resData.data);
        saveToken(resData.data, res?.data);
      }
      if (res?.data?.role === "ADMIN") {
        await dispatch(adminDetails());
      } else if (res?.data?.role === "REFERRAL_MANAGER" || res?.data?.role === "REPORT_MANAGER") {
        dispatch(setAuthState({
          isAuthenticated: true,
          isLoading: false,
          user: {},
          role: res?.data?.role
        }));
      }
    }
    return res;
  } catch (error) {
    console.error("signInConfirm error => ", error);
    console.log("Error Response signInConfirm => ", error.response);
    return getResData(error);
  }
};

const role = localStorage.getItem("role")

export const adminDetails = () => async (dispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const res = await request.get(URL_ADMIN_DETAILS);
    const role = localStorage.getItem("role")
    const resData = getResData(res.data);
    if (!resData.success) {
      request.deleteAuthorizationToken();
      dispatch(setAuthState({
        isAuthenticated: false,
        isLoading: false,
        user: {},
      }));
      return resData;
    }
    dispatch(setAuthState({
      isAuthenticated: true,
      isLoading: false,
      user: {},
      role: role
    }));
    return resData;
  } catch (error) {
    if (role === "ADMIN") {
      dispatch(setAuthState({
        isAuthenticated: false,
        isLoading: false,
        user: {},
        role: role
      }));
      return null;
    } else if (role !== "ADMIN") {
      dispatch(setAuthState({
        isAuthenticated: true,
        isLoading: false,
        user: null,
        role: role
      }));
      return null;
    } else {
      console.error("fetchUser error => ", error);
      return null;
    }

  }
};

