export const getToken = () => {
    const token = localStorage.getItem("token");
    if (!token) return null;
    return token;
};

export const getRole = () => {
    const role = localStorage.getItem("role");
    if (!role) return null;
    return role;
};

export const getRefreshToken = () => {
    const refreshData = localStorage.getItem("refreshData");
    if (!refreshData) return null;
    return JSON.parse(refreshData);
};

export const saveToken = (token, data) => {
    if (!token) return null;
    localStorage.setItem("token", token);
    localStorage.setItem("role", data?.role);
    localStorage.setItem("refreshData", JSON.stringify(data));
};

export const deleteToken = () => {
    localStorage.removeItem("refreshData");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
};
