import { deleteToken, getToken } from "./localStorage";

const parseJSON = async (response) => {
    const result = {
        response: response,
        data: null,
    };
    try {
        if (response.status === 204 || response.status === 205) {
            return result;
        }
        if (response.ok) {
            const data = await response.json();
            result.data = data;
            return result;
        }
        return response;
    } catch (error) {
        return {
            response: response,
            data: null,
        };
    }
};

const handleError = (response) => {
    let errorText = "";
    switch (true) {
        case !response: {
            errorText = "Unexpected error occurred";
            break;
        }

        case response.statusText: {
            errorText = response.statusText;
            break;
        }
        case !response.statusText && response.status === 403: {
            errorText = "Restricted access";
            break;
        }
        case !response.statusText && response.status === 404: {
            errorText = "Not Found";
            break;
        }

        case !response.statusText && response.status >= 500: {
            errorText = "Internal server error";
            break;
        }

        default:
            errorText = "Unexpected error occurred";
            break;
    }
    const error = new Error(errorText);
    error.response = response;
    throw error;
};

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return parseJSON(response);
    }
    handleError(response);
}

class Request {
    constructor() {
        this.defaultOptions = {
            headers: {},
        };
    }

    setAuthorizationToken(token) {
        if (token) {
            this.defaultOptions.headers.Authorization = `Bearer ${token}`;
        }
    }

    deleteAuthorizationToken() {
        this.defaultOptions.headers = {};
        deleteToken();
    }

    getDefaultHeaders(type) {
        if (["POST", "PUT"].includes(type)) {
            return {
                ...this.defaultOptions.headers,
                "Content-Type": "application/json",
            };
        }
    }

    checkOldToken() {
        const token = getToken();
        const originalToken = this.defaultOptions.headers.Authorization;
        if (!originalToken && !token) return;
        if (token && originalToken && !originalToken.includes(token)) {
            this.deleteAuthorizationToken();
            return window.location.reload();
        }
    }

    normalizeUrl(url) {
        if (!url || url.length === 0) return url;
        return url[0] === "/" ? url.slice(1) : url;
    }

    // HTTP methods
    get(url, options = {}) {
        this.checkOldToken();
        url = this.normalizeUrl(url);
        return fetch(`${window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
            ...options,
            ...this.defaultOptions,
            method: "GET",
        })
            .then(checkStatus)
            .catch(handleError);
    }
    post(url, data = {}, options = {}) {
        this.checkOldToken();
        url = this.normalizeUrl(url);
        return fetch(`${window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
            body: JSON.stringify(data),
            ...options,
            headers: {
                ...this.getDefaultHeaders("POST"),
                ...options.headers,
            },
            method: "POST",
        })
            .then(checkStatus)
            .catch(handleError);
    }
    put(url, options = {}) {
        this.checkOldToken();
        url = this.normalizeUrl(url);
        return fetch(`${window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
            ...options,
            headers: {
                ...this.getDefaultHeaders("PUT"),
                ...options.headers,
            },
            method: "PUT",
        })
            .then(checkStatus)
            .catch(handleError);
    }
    delete(url, options = {}) {
        this.checkOldToken();
        url = this.normalizeUrl(url);
        return fetch(`${window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
            ...options,
            ...this.defaultOptions,
            method: "DELETE",
        })
            .then(checkStatus)
            .catch(handleError);
    }
    loadFile(url, options = {}) {
        var fileName;
        this.checkOldToken();
        url = this.normalizeUrl(url);
        return fetch(`${window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
            ...options,
            ...this.defaultOptions,
            method: "GET",
        })
            .then((response) => {
                fileName = response.headers.get("fileName");
                return response.blob();
            })
            .then((blob) => {
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName ? fileName : `file.txt`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(handleError);
    }
}

const request = new Request();

export default request;
