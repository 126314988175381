import { SET_AUTH_LOADING, SET_USER, SET_IS_AUTHENTICATED, SET_AUTH_STATE, SET_ROLE } from "./constants";

export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});

export const setAuthLoading = (isLoading) => ({
    type: SET_AUTH_LOADING,
    payload: isLoading,
});

export const setAuthAuthenticated = (isAuthenticated) => ({
    type: SET_IS_AUTHENTICATED,
    payload: isAuthenticated,
});

export const setRole = (role) => ({
    type: SET_ROLE,
    payload: role,
});

export const setAuthState = (authState) => {
    return ({
        type: SET_AUTH_STATE,
        payload: authState,
    })
};
